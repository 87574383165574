import React, { PropsWithChildren } from 'react'
import TextButton, { VARIANTS } from 'mixtiles-web-common/ui/TextButton'
import { ReactComponent as ErrorIcon } from './icons/error.svg'
import { ReactComponent as ReloadIcon } from './icons/reload.svg'
import useOnMount from '../../hooks/useOnMount'
import { analytics } from '../../services/Analytics/Analytics'
import { ErrorBoundary } from 'react-error-boundary'
import { navigationManager } from '../../services/NavigationManager'
import { photosTheme } from 'mixtiles-web-common'
import * as S from './AppErrorFallback.styles'
import './AppErrorFallback.scss'
import { logger } from '../../services/logger'
import { extractErrorMessage } from '../../utils/ApiUtils'
import { intercomService } from '../../services/Analytics/platforms/intercom'
import { useProductType } from '../../services/ProductTypesManager'

export function AppErrorFallbackWrapper({
  children,
  themeColor,
  ...props
}: PropsWithChildren<{ themeColor?: string }>) {
  const { productType } = useProductType()
  const onReset = () => {
    navigationManager.goToHomePage(productType)
  }
  return (
    <ErrorBoundary
      onReset={onReset}
      fallbackRender={({ error, resetErrorBoundary }) => (
        <AppErrorFallback
          themeColor={themeColor}
          {...props}
          error={error}
          resetErrorBoundary={resetErrorBoundary}
        />
      )}
    >
      {children}
    </ErrorBoundary>
  )
}

function AppErrorFallback({
  themeColor = photosTheme.colors.primary,
  error,
  resetErrorBoundary,
}: {
  themeColor?: string
  error: Error
  resetErrorBoundary: () => void
}) {
  useOnMount(() => {
    analytics.track('App Error Occurred', {
      error: extractErrorMessage(error),
      stacktrace: error?.stack,
      path: window.location.pathname,
    })
    logError(error)
  })

  const onTryAgainPressed = () => {
    analytics.track('Try Again Tapped', { screen: 'AppErrorFallback' })
    resetErrorBoundary()
  }

  const onTalkToUsPressed = () => {
    analytics.track('Talk to Us Tapped', { screen: 'AppErrorFallback' })
    intercomService.show()
  }

  /**
   * Write error to log in order to be monitored
   */
  const logError = (error: Error) => {
    // most errors reaching here will not be reported to sentry from this call but before that
    // because sentry manages to capture the errors before the ErrorBoundary does and marks them
    // reported https://github.com/getsentry/sentry-javascript/pull/4067
    logger.error('App Error Occurred - Fallback Loaded', error, {
      tags: {
        isFallback: true,
      },
    })
  }

  return (
    <div className="AppErrorFallback">
      <div className="error-page-icon">
        <ErrorIcon fill={themeColor} />
      </div>
      <S.Title>Something went wrong</S.Title>
      <S.Description>
        Oops! There was a problem loading our website.
        <br />
        Please try again in a few minutes.
      </S.Description>
      <br />
      <div
        className="try-again-button"
        onClick={onTryAgainPressed}
        style={{ backgroundColor: themeColor }}
      >
        <ReloadIcon className="try-again-icon" />
        <div className="try-again-text">Try Again</div>
      </div>
      <div className="talk-to-us-container">
        <TextButton variant={VARIANTS.underline} onClick={onTalkToUsPressed}>
          Talk to Us
        </TextButton>
      </div>
    </div>
  )
}
